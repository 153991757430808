<template>
  <div class="box-body">
    <ul class="timeline timeline-inverse" v-for="weekly_report_item in car_weekly_reports">
      <li class="time-label">
        <span class="bg-gray-active">
          {{ weekly_report_item.created_sub_week_date }} - {{ weekly_report_item.created_date }}
        </span>
      </li>
      <li>
        <div class="timeline-item no-border">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Просмотров на сайте</th>
              <th scope="col">Просмотров AutoRIA</th>
              <th scope="col">Переходов AutoRIA</th>
              <th scope="col">Задач</th>
              <th scope="col">Звонков</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ weekly_report_item.views }}</td>
              <td>{{ weekly_report_item.autoria_views }}</td>
              <td>{{ weekly_report_item.autoria_clicks }}</td>
              <td>{{ weekly_report_item.tasks_count }}</td>
              <td>{{ weekly_report_item.calls_count }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </li>
      <li>
        <i class="fa fa-clock-o bg-gray"></i>
      </li>
    </ul>
    <div class="panel panel-default" v-if="car_weekly_reports.length === 0">
      <div class="panel-body text-center text-muted mt-5 mb-5">
        Нет элементов для отображения
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'car-weekly-report',

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    car_weekly_reports: [],
  }),

  async created() {
    try {
      await this.getCarWeeklyReports();
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getCarWeeklyReports() {
      try {
        const car_weekly_reports_response = await API.apiClient.get(
            '/car/' + this.carId + '/weekly_report'
        );

        this.car_weekly_reports = car_weekly_reports_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
  },
}
</script>

<style scoped>

</style>
